<template>
    <div id="md-view" class="mobile_page-container">
        <VueMarkdown
            class="api-content"
            :source="markdownContent"
            id="content"
        />
    </div>
</template>

<script>
import VueMarkdown from "vue-markdown";
export default {
    name: 'file',
    components: {
        VueMarkdown,
    },
    data() {
        return {
            markdownContent: ''
        }
    },
    watch: {
        $route() {
            this.loadMarkdownFile(this.$route.query.name)
        }
    },
    mounted() {
        this.loadMarkdownFile(this.$route.query.name)
    },
    methods: {
        async loadMarkdownFile(name) {
            try {
                const response = await fetch(`file/${name}.md`)
                const markdownText = await response.text()
                this.markdownContent = markdownText
            } catch (error) {
                console.error("Failed to load the Markdown file:", error);
            }
        }, 
    }
}
</script>
<style lang="less">
#md-view {
    padding-top: 30px;
    padding-bottom: 30px;
}
#md-view h1 {
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    line-height: 70px;
}
#md-view p {
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
    text-align: left;
    color: #151515;
}
#md-view h3 {
    margin-top: 48px;
    font-size: 28px;
    line-height: 60px;
    font-weight: 700;
    text-align: left;
}
#content >p:nth-of-type(1) {
    line-height: 48px;
}
</style>